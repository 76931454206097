<template>
  <div class="base-box">
    <div id="myImage" ref="myImage" class="image-container">
      <img src="/NiuNiu-White.svg" alt="logo" class="logo" />
      <div class="heading-box">
        <h1 class="heading-primary">
          <span class="heading-primary-main">Niu - Niu</span>
          <span class="heading-primary-sub">十八层牛肉巨无霸</span>
        </h1>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import { gsap } from "gsap";

const myImage = ref<HTMLDivElement | null>(null);

onMounted(() => {
  fetch("http://moyuwcc.cn/potato/images/GetRDBingWallpaper?isUHD=false")
    .then((res) => res.text())
    .then((imgurl) => {
      if (myImage.value) {
        // 在动画之前设置完整的背景图像
        myImage.value.style.backgroundImage = `linear-gradient(to right bottom, rgba(119, 119, 119) , rgba(119, 119, 119) ),url(${imgurl})`;

        // 仅在动画中处理遮罩的透明度变化
        gsap.fromTo(myImage.value,
          { backgroundImage: `linear-gradient(to right bottom, rgba(119, 119, 119) , rgba(119, 119, 119) ),url(${imgurl})` },
          { backgroundImage: `linear-gradient(to right bottom, rgba(204, 204, 204, 0.5) , rgba(119, 119, 119, 0.5) ),url(${imgurl})`, duration: 1, delay: 0.5 }
        );
      }
    });
});
</script>

<style scoped>
@import url(../css/Main.css);
</style>
