import { createApp } from "vue";
import "./style.css";
import App from "./App.vue";
import 'element-plus/dist/index.css';

const app = createApp(App);

// 配置中文会增加不必要的首屏加载负担
// 相当于全局加载，单独引入就没有必要了
// 所以在需要的时候配置中文，而不是全局配置

// 配置 element-plus 支持中文
// app.use(ElementPlus, {
//   locale: zhCn,
// });

app.mount("#app");
